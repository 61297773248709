import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Envoirnmental Engineering and Sewerage`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1iO0iaXKGxSkzNC_0FXam9l50dXgx1FWt"
      }}>{`Download: Wastewater engineering treatment and reuse by Metcalf and Eddy 4th Ed`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=18HPjGiM_EOYloNQGKzOHKJB_WsF6vMma"
      }}>{`Download: Nitrate and nitrite in drinking water by WHO`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1TtuDarhvkJMiHgimv1kVHT3lkxoKqNw6"
      }}>{`Download: Total dissolved solids in drinking water by WHO`}</a></p>
    <h2>{`Lab experiments`}</h2>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/to-determine-biological-oxygen-demand-in-domestic-waste-water/"
      }}>{`To determine biological oxygen demand in domestic waste water`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/to-determine-chemical-oxygen-demand-in-water/"
      }}>{`To determine chemical oxygen demand in water`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/to-determine-amount-of-dissolved-oxygen-in-water/"
      }}>{`To determine amount of dissolved oxygen in water`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/to-determine-amount-of-nitrogen-in-water-sample/"
      }}>{`To determine amount of nitrogen in water sample`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/complete-environmental-engineering-ii-lab-manual/"
      }}>{`Complete environmental engineering II lab manual `}</a></p>
    <h3>{`Sewerage system design:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1MmV95U2XSVZL271W4FCZHNrB2ed4sJjl"
      }}>{`Download full report+ excel worksheet for design of sewerage network of society`}</a></p>
    <h2>{`Lecture slides`}</h2>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_1-Wastewater_Collection_System_.pdf"
      }}>{`Lec`}<em parentName="a">{`1 Wastewater_Collection_System`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_2-Design_of_Sewer_System_.pdf"
      }}>{`Lec`}<em parentName="a">{`2 Design_of_Sewer_System`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_3-partially_full_sewers_Numericals_DSS_.pdf"
      }}>{`Lec`}<em parentName="a">{`3 Partially_full_sewers_Numericals`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_4-Invert_levels_.pdf"
      }}>{`Lec`}<em parentName="a">{`4 Invert_levels`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_5-Design_of_storm_sewer_.pdf"
      }}>{`Lec`}<em parentName="a">{`5 Design_of_storm_sewer`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_6-Construction_of_Sewers_and_Bedding.pdf"
      }}>{`Lec_6 Construction_of_Sewers_and_Bedding`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_7-Design_of_Pumping_Station_.pdf"
      }}>{`Lec`}<em parentName="a">{`7 Design_of_Pumping_Station`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_8-Wastewater_Characteristics_.pdf"
      }}>{`Lec`}<em parentName="a">{`8 Wastewater_Characteristics`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_9_Pre-Treatment_.pdf"
      }}>{`Lec`}<em parentName="a">{`9_Pre-Treatment`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_10_Primary-Treatment_.pdf"
      }}>{`Lec`}<em parentName="a">{`10_Primary-Treatment`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_11_Secondary_Treatment_.pdf"
      }}>{`Lec`}<em parentName="a">{`11_Secondary_Treatment`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_12_Aerated_Lagoons_.pdf"
      }}>{`Lec`}<em parentName="a">{`12_Aerated_Lagoons`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_13_Trickling_Filters_.pdf"
      }}>{`Lec`}<em parentName="a">{`13_Trickling_Filters`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec_14_Waste_Stablization_Ponds_.pdf"
      }}>{`Lec`}<em parentName="a">{`14_Waste_Stablization_Ponds`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-15-Design-of-Secondary-Clarifier.pdf"
      }}>{`Lec 15 Design of Secondary Clarifier)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-16-On-site-Saintation-Treatment.pdf"
      }}>{`Lec 16 On site – Sanitation Treatment`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-17-EIA.pdf"
      }}>{`Lec 17 Environmental impact assessment`}</a></p>
    <h3>{`Solution to class problems`}</h3>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/file/d/1TjmXBeU2RU9mld1MSjjpVhUYrYtbBdBM/view"
      }}>{`Solution to class problems`}</a></p>
    <h3>{`Miscellaneous topics:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/design-manual-for-waste-stabilization-ponds.pdf"
      }}>{`Design manual for waste stabilization ponds`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/grit-chamber-example.pdf"
      }}>{`Grit chamber example`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/grit-chamber.pdf"
      }}>{`Grit chamber`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/grit-chamber1.pdf"
      }}>{`Grit chamber1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-Sludge-Treatment-Compatibility-Mode.pdf"
      }}>{`Lec- Sludge Treatment`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/screening-and-grit-removal.pdf"
      }}>{`Screening and grit removal`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/sewer-appertenences.pdf"
      }}>{`Sewer appurtenances`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/waste-stabilization-ponds.pdf"
      }}>{`Waste stabilization ponds`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/waste-stabilization-ponds1.pdf"
      }}>{`Waste stabilization ponds1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/wastewater-treatment.pdf"
      }}>{`Wastewater treatment`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      